import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';

// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';

// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------
// JWT
const LoginPage = lazy(() => import('src/pages/auth/login'));
const RegisterPage = lazy(() => import('src/pages/auth/register'));
const ForgotPasswordPage = lazy(() => import('src/pages/auth/forgot-password'));

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <GuestGuard>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </GuestGuard>
    ),
    children: [
      {
        path: 'login',
        element: (
          <AuthClassicLayout>
            <LoginPage />
          </AuthClassicLayout>
        ),
      },
      {
        path: 'register',
        element: (
          <AuthClassicLayout title="Manage the job more effectively with Minimal">
            <RegisterPage />
          </AuthClassicLayout>
        ),
      },
      {
        element: (
          <AuthModernCompactLayout>
            <Outlet />
          </AuthModernCompactLayout>
        ),
        children: [
          { path: 'forgot-password', element: <ForgotPasswordPage /> },
        ],
      },
    ],
  },
];
