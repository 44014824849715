import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios, { endpoints, apiCall } from 'src/utils/axios';

import { ApiUserMeResponseModel, ApiUserLoginRequestModel, ApiUserLoginResponseModel, ApiUserRegistrationRequestModel } from 'src/types/api';
//
import { AuthContext } from './auth-context';
import { setSession } from './utils';
import { ActionMapType, AuthContextType, AuthStateType } from '../types';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: ApiUserMeResponseModel | null;
  };
  [Types.LOGIN]: {
    user: ApiUserLoginResponseModel | null;
  };
  [Types.REGISTER]: {
    user: ApiUserLoginResponseModel | null;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {

    const accessToken = sessionStorage.getItem(STORAGE_KEY);
    if (!accessToken) {
      dispatch({ type: Types.INITIAL, payload: { user: null, }, });
      return;
    }
    setSession(accessToken);
    const res = await apiCall<ApiUserMeResponseModel>(endpoints.user.me);
    if (!res?.Ok) {
      setSession(null);
      dispatch({ type: Types.INITIAL, payload: { user: null, }, });
      return;
    }
    dispatch({ type: Types.INITIAL, payload: { user: res.Data, }, });
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (username: string, password: string) => {
    const data: ApiUserLoginRequestModel = {
      UserName: username,
      Password: password,
    };

    const res = await apiCall<ApiUserLoginResponseModel>(endpoints.user.login, data);

    if (res?.Ok && res.Data) {
      setSession(res.Data.AuthToken);
      dispatch({ type: Types.LOGIN, payload: { user: res.Data, }, });
    }
    return res;
  }, []);

  // REGISTER
  const register = useCallback(async (username: string, password: string) => {
    const data: ApiUserRegistrationRequestModel = {
      UserName: username,
      Password: password,
    };

    const res = await apiCall<ApiUserLoginResponseModel>(endpoints.user.register, data);

    if (res?.Ok && res.Data) {
      setSession(res.Data.AuthToken);
      dispatch({
        type: Types.REGISTER,
        payload: {
          user: res.Data,
        },
      });
    }
    return res;
  }, []);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email: string) => {
    const data = {
      email,
    };

    const res = await axios.post(endpoints.user.register, data);
    const { accessToken, user } = res.data;
    dispatch({
      type: Types.REGISTER,
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
      forgotPassword,
    } as AuthContextType),
    [login, logout, register, forgotPassword, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
