import axios, { AxiosRequestConfig } from 'axios';
// config
import { HOST_API } from 'src/config-global';
import { ApiResponseModel } from 'src/types/api';
// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

export const apiCall = async function <T>(url: string, data?: any): Promise<ApiResponseModel<T> | null> {
  try {
    const apiResponse = await axiosInstance.post<ApiResponseModel<T>>(url, data);
    if (apiResponse && apiResponse.data && apiResponse.data.Ok === false && apiResponse.data.Status === 401) {
      console.log('should logout')
    }
    else {
      return apiResponse.data;
    }
  }
  catch (error) {
    console.error(error)
  }
  return null;
}
// ----------------------------------------------------------------------

export const endpoints = {
  user: {
    me: '/user/me',
    login: '/user/login',
    register: '/user/register',
  },
};
